@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-gray-900;
}

* {
  @apply transition-all;
}

/* @font-face {
  font-family: "Biko Bold";
  src: local("Biko Bold"), url("./Biko_Bold.otf") format("opentype");
} */
@font-face {
  font-family: 'Biko Regular';
  src: local('Biko Regular'), url('./Biko_Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Metropolis Light';
  src: local('Metropolis Light'),
    url('./Metropolis_Light.otf') format('opentype');
}
@font-face {
  font-family: 'Metropolis Medium';
  src: local('Metropolis Medium'),
    url('./Metropolis_Medium.otf') format('opentype');
}
